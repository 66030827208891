<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { MagazinShareProps } from '$lib/storyblok/types';
	import { T } from '@tolgee/svelte';
	import ProductShareButton from '$lib/components/Storyblok/ProductShareButton.svelte';

	export let blok: MagazinShareProps;
</script>

<div use:storyblokEditable={blok}>
	<div class="flex flex-col-reverse w-full space-y-reverse md:flex-col box-x-wrapper">
		<div class="flex flex-row justify-center w-full h-full z-0">
			<div class="md:flex md:flex-col w-full md:w-2/3 md:pl-1.5 text-center">
				<h3 class="text-h-16 mb-4">
					<T keyName="magazinShareHeadline" />
				</h3>
				<ProductShareButton title={blok.title} image={blok.image} showShareIcons={true} />
			</div>
		</div>
	</div>
</div>
