<script lang="ts">
	import type { MagazinTitleProps } from '$lib/storyblok/types';
	import { T } from '@tolgee/svelte';
	import { languageStore } from '$lib/store/language.store';

	export let blok: MagazinTitleProps;

	$: dateString = (blok?.date ? new Date(blok?.date) : new Date()).toLocaleDateString(
		$languageStore.locale,
		{
			day: '2-digit',
			month: 'long',
			year: 'numeric'
		}
	);
</script>

<div class="flex flex-col-reverse w-full space-y-reverse md:flex-col box-x-wrapper">
	<div class="flex flex-row justify-center w-full h-full z-0">
		<div class="md:flex md:flex-col w-full md:w-2/3 md:pl-1.5 text-center">
			<h1 class="text-h-32 md:text-h-72 mb-2 md:mb-4 pt-11 md:pt-24">
				{blok?.title || 'Kein Titel'}
			</h1>
			<span class="text-p-12">
				<T keyName="magazinTitleByAuthor" />
				{blok?.author || 'kein Author angegeben'}, {dateString}
			</span>
			<div
				class="mt-4 md:mt-8 i-sprenger-magazin-title-line w-full h-10 md:h-16 text-accent-primary"
			/>
		</div>
	</div>
</div>
